import React from "react"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function AucklandMatamataReturnTransfer(props) {
  return (
    <>
      <SEO title="Travel from Auckland to Matamata to visit Hobbiton - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/slideshow/hobbit-door.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWAXA" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>
                  Auckland-Matamata return coach transfer for Hobbiton movie set
                </h1>
                <div class="highlights">
                  <ul>
                    <li>Luxury return coach travel from Auckland and back</li>
                    <li>Informative driver commentary</li>
                    <li>Rolling Waikato farmland scenery</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Have you already booked your Movie Set Tour? Choose us for
                      your transport from Auckland and back!
                      <br />
                      <br />
                    </p>
                    <div class="highlights">
                      <h4>HIGHLIGHTS:</h4>
                      <br />
                      <ul>
                        <li>
                          Luxury return coach travel from Auckland and back
                        </li>
                        <li>Informative driver commentary</li>
                        <li>Rolling Waikato farmland scenery</li>
                      </ul>
                    </div>

                    <div class="clear"></div>

                    <p>
                      Leaving Auckland in the morning by deluxe coach, we travel
                      through the lush farmland of the Waikato. Your experienced
                      local driver guide will inform and entertain you as you
                      travel to the Hobbiton Movie Set. After your movie set
                      tour, board the coach to return to Auckland.
                    </p>
                    <p>
                      Please make sure that your Movie Set Tour departs from the
                      Shire's Rest. To fit with our coach timings, your Movie
                      Set Tour should depart Matamata no earlier than 12.15 and
                      return to Matamata no later than 3:15pm.
                    </p>

                    <div id="Summary" class="row">
                      <div id="right-col">
                        <h3>Reporting Time</h3>
                        <p>
                          15 minutes prior to departure - from Sky City,
                          Auckland at 9.30AM, returning at 6.30pm
                        </p>
                      </div>
                      <h3>Our Coaches</h3>
                      <p>Luxury, air conditioned, full size touring coaches</p>
                      <h3>Commentary</h3>
                      <p>
                        A full commentary is provided in English from your
                        driver.
                      </p>
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default AucklandMatamataReturnTransfer
